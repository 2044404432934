<template lang="pug">
    div
        table.table-auto.border-collapse.border.border-slate-400.w-full.bg-white
            table-header(:list="FILES_LIST_TABLE_HEADERS")
            tbody
                template(v-for="(item, index) in files")
                    tr(:key="item._id")
                        td.border.border-slate-300.w-10.p-2.font-light.text-xs.text-center
                            span {{ index + 1 }}
                        td.border.border-slate-300.w-20.p-2
                            template(v-if="getFileType({ file_type: item.type }).type === 'image'")
                                img.cursor-pointer(
                                    :src="base_url + '/' + (item.file_id.sm_path ? item.file_id.sm_path : item.path)" 
                                    @click="onImage({ url: base_url + '/' + item.path })"
                                )
                            template(v-else)
                                img.m-auto(:src="getFileType({ file_type: item.type }).icon")
                        td.text-sm.font-thin.border.border-slate-300.text-center {{ item.type }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            template(v-if="title_edit_id !== item._id")
                                p.cursor-pointer(@click="onField({id: item._id, field: 'title'})") {{ item.title }} &#9998;
                            template(v-else)
                                edit-input-wrapper( @cancel="onCancelEdit('title_edit_id')" @accept="onFieldEdit({ id: item._id, value: title_edit_value, field: 'title' })" )
                                    text-area(:value="item.title" @value="title_edit_value = $event")
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.cursor-pointer 
                            template(v-if="description_edit_id !== item._id")
                                p.cursor-pointer(@click="onField({id: item._id, field: 'description'})") {{ item.description }} &#9998;
                            template(v-else)
                                edit-input-wrapper( @cancel="onCancelEdit('description_edit_id')" @accept="onFieldEdit({ id: item._id, value: description_edit_value, field: 'description' })" )
                                    text-area(:value="item.description" @value="description_edit_value = $event")
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center {{ item.size ? formatBytes(item.size) : '-' }}
                        td.text-xs.font-thin.border.border-slate-300.text-center(:class="{'font-medium': isNewFile(item)}") {{ item.created_at | moment("dddd, MMMM Do YYYY") }}
                        td.text-sm.font-thin.border.border-slate-300.text-center
                            switcher-style-one.m-auto(
                                @on-switch="onStatusChange(item)"
                                :status="+item.status ? true : false"
                            )
                        td.text-sm.font-thin.border.border-slate-300.text-center.text-gray-500 
                            inline-svg.m-auto(
                                :src="ICONS.bin" 
                                @click="() => { if (deleteFilter) {onDeleteConfirmation(item)} }"
                                :fill-opacity="deleteFilter ? 1 : 0.25"
                            )
        button(@click="onLoadMore") Загрузить еще
</template>

<script>
//Components
import SwitcherStyleOne from '@/components/common/switchers/style-1';
import TextArea from '@/components/common/forms/text-area';
import EditInputWrapper from '@/components/common/forms/edit-wrapper';
import TableHeader from '@/components/common/tables/table-header';
//Store
import { FilesNameSpace, FilesActionTypes, FilesGetterTypes, FilesMutationTypes } from '@/store/files/types';
import { RootGetterTypes } from '@/store/types';
//Plugins
import moment from 'moment';
//Helpers
import { formatBytes } from '@/helpers/format-bytes';
import { getFileType } from '@/helpers/get-file-type';
//Configs
import { FILES_LIST_TABLE_HEADERS } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Mixins
import { deleteConfirmation, closeModalDialog } from '@/mixins/modals';

export default {
    name: "FilesComponent",
    mixins: [deleteConfirmation, closeModalDialog],
    components: {
        'switcher-style-one': SwitcherStyleOne,
        'text-area': TextArea,
        'edit-input-wrapper': EditInputWrapper,
        'table-header': TableHeader,
    },
    data() {
        return {
            title_edit_id: '',
            title_edit_value: '',
            description_edit_id: '',
            description_edit_value: '',
            delete_file_id: null,
            currentPage: 0,
            perPage: 50,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        FILES_LIST_TABLE_HEADERS() {
            return FILES_LIST_TABLE_HEADERS
        },
        files() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetFiles}`]
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
    },
    methods: {
        moment() {
            return moment()
        },
        formatBytes(value) {
            return formatBytes(value)
        },
        getFileType({ file_type }) {
            return getFileType({ file_type })
        },
        isNewFile({ created_at }) {
            let result = false
            if (this.moment(new Date()).format("X") === this.moment(created_at).format("X")) result = true
            return result
        },
        onLoad({ per_page, page }) {            
            this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.GetFiles}`, {params: { per_page, page }})
        },
        onLoadMore() {
            this.currentPage = this.currentPage + 1
            this.onLoad({ per_page: this.perPage, page: this.currentPage })
        },
        onStatusChange({ _id, status }) {
            let status_value = Number(status)
            status_value = !status_value
            this.onFileChange(
                {   
                    id: _id, 
                    value: {status: status_value},
                } 
            )
        },
        async onFieldEdit({ id, value, field }) {
            await this.onFileChange({ id: id, value: { [field]: value } })
            this[`${field}_edit_id`] = ''
        },
        onField({ id, field }) {
            this[`${field}_edit_id`] = id
        },
        onCancelEdit(value) {
            this[value] = ''
        },
        onImage({ url }) {
            window.open(url)
        },
        async onFileChange({ id, value }) {
            await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.EditFile}`, { id, value })
        },
        async onDeleteConfirmation({_id}) {
            this.delete_file_id = _id
            this.deleteConfirmation({ confirm: 'onDeleteFile' })
            
        },
        async onDeleteFile() {
            await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.DeleteFile}`, { id: this.delete_file_id }) 
            this.closeModalDialog()
            this.delete_file_id = null
        }
    },
    mounted() {
        this.onLoad({ per_page: this.perPage, page: this.currentPage })
    },
    beforeDestroy() {
        this.$store.commit(`${FilesNameSpace}/${FilesMutationTypes.SetEmptyFiles}`)
    }
}
</script>