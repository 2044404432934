<template lang="pug">
    .div
        filters
            template(v-slot:statistics)
                statistics
                    template(v-slot:total)
                        span {{ filesResponse.total }}
        files-list
</template>

<script>
//Components
import FilesList from '@/components/views/files';
import Filters from '@/components/common/filters';
import Statistics from '@/components/common/filters/base-statistics';
//Store
import { FilesNameSpace, FilesGetterTypes } from '@/store/files/types';

export default {
    name: "FileListPage",
    components: {
        'files-list': FilesList,
        'filters': Filters,
        'statistics': Statistics,
    },
    computed: {
        filesResponse() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetResponse}`]
        }
    }
}
</script>